/* eslint-disable */
import * as moment from 'moment';

export const notifications = [
    {
        id         : '493190c9-5b61-4912-afe5-78c21f1044d7',
        icon       : 'heroicons_solid:star',
        title      : 'Today appointments',
        description: 'Your list of today appointments',
        time       : moment().subtract(25, 'minutes').toISOString(), // 25 minutes ago
        read       : false
    },
    {
        id         : 'b91ccb58-b06c-413b-b389-87010e03a120',
        icon       : 'heroicons_solid:document-report',
        title      : 'Report',
        description: 'You daily report are ready to view',
        time       : moment().subtract(3, 'hours').toISOString(), // 3 hours ago
        read       : false,
        link       : '/dashboards/project',
        useRouter  : true
    },
    {
        id         : '541416c9-84a7-408a-8d74-27a43c38d797',
        icon       : 'heroicons_solid:refresh',
        title      : 'Incomplete tasks',
        description: 'You have 3 incomplete tasks',
        time       : moment().subtract(5, 'hours').toISOString(), // 5 hours ago
        read       : false,
        link       : '/dashboards/project',
        useRouter  : true
    }
];
