import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { CadMediaWatcherService } from "@cad/services/media-watcher";
import {
  CadNavigationService,
  CadVerticalNavigationComponent,
} from "@cad/components/navigation";
import { Navigation } from "app/core/navigation/navigation.types";
import { NavigationService } from "app/core/navigation/navigation.service";
import { IgxDialogComponent } from "@infragistics/igniteui-angular";
import { AuthService } from "app/core/auth/auth.service";
import { AppConfigService } from "app/appconfig.service";
import { Router } from "@angular/router";
import { environment } from "environments/environment";
import { VcService } from "app/shared/vc.service";
import { UserComponent } from "app/layout/common/user/user.component";
import { FhirPathService } from "app/fhirpath.service";
import { AppService } from "app/app.service";

@Component({
  selector: "modern-layout",
  templateUrl: "./modern.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class ModernLayoutComponent implements OnInit, OnDestroy {
  isScreenSmall: boolean;
  encounter: fhir.r4.Encounter;
  navigation: Navigation;
  vcAvailable: boolean = false;
  totalVC: any;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  @Input() iconTpl: TemplateRef<any>;

  @ViewChild("alert", { static: true })
  public alert: IgxDialogComponent;

  //@HostListener("document:mousemove", ["$event"])
  onMouseMove(e) {
    if (this.sessionEndStart) {
      this.sessionEndStart = false;
      this.alert.close();
      this.resetTimer();
    } else {
      this.resetTimer();
    }
  }

  min: number;
  sec: number;
  interval;
  subscribeTimer: any;
  sessionEndStart = false;
  username: string;
  vcLink: string;

  /**
   * Constructor
   */
  constructor(
    private _navigationService: NavigationService,
    private _cadMediaWatcherService: CadMediaWatcherService,
    private _cadNavigationService: CadNavigationService,
    private _authService: AuthService,
    private _appConfigService: AppConfigService,
    private _vcService: VcService,
    private _fhirPathService: FhirPathService,
    private _appService: AppService,
    private _router: Router,
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    //this.resetTimer();
    //this.sessionEndStart = false;
    //this.startTimer();
    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });

    // Subscribe to media changes
    this._cadMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes("md");
      });

    setTimeout(() => {
      this.username = this._appConfigService.getUserName();
    }, 1000);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */

  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._cadNavigationService.getComponent<CadVerticalNavigationComponent>(
        name
      );

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (!this.sessionEndStart) {
        if (
          (this.min > 0 && this.sec > 0) ||
          (this.min === 0 && this.sec > 15)
        ) {
          this.sec--;
        } else if (this.min > 0) {
          this.sec = 59;
          this.min--;
        } else if (this.min === 0 && this.sec > 0 && this.sec <= 15) {
          this.alert.open();
          this.sessionEndStart = true;
        }
      } else {
        if (this.sec > 0) {
          this.sec--;
        } else {
          this.alert.close();
          this.stopTimer();

          localStorage.clear();
          // this._authService.logout();

          if (environment.environmentName === "PRODUCTION") {
            this._authService.logout("/portal");
          } else {
            this._authService.logout("");
          }
        }
      }
    }, 1000);
  }

  stopTimer() {
    clearInterval(this.interval);
  }

  checkEnvironment() {
    if (
      environment.fhirURL.includes("hie") ||
      environment.environmentName.includes("PRODUCTION") ||
      environment.environmentName.includes("STAGING")
    ) {
      return true;
    } else {
      return false;
    }
  }

  resetTimer() {
    this.min = this._appConfigService.getMinSession()
      ? this._appConfigService.getMinSession()
      : 6;
    this.sec = this._appConfigService.getSecSession()
      ? this._appConfigService.getSecSession()
      : 59;
  }
}
