/* eslint-disable */
import { CadNavigationItem } from "@cad/components/navigation";

export const defaultNavigation: CadNavigationItem[] = [
  {
    id: "apps",
    title: "Applications",
    subtitle: "Application main menu",
    type: "group",
    icon: "heroicons_outline:home",
    children: [
      {
        id: "patient",
        title: "my-health-record",
        type: "basic",
        icon: "mat_outline:health_and_safety",
        link: "/apps/patient/health-timeline",
        //link    : '/apps/patient/booking'
      },
      {
        id: "donation",
        title: "my-blood-donation",
        type: "basic",
        icon: "heroicons_outline:calendar",
        link: "/apps/donation/dashboard",
      },
    ],
  },
];
export const compactNavigation: CadNavigationItem[] = [
  {
    id: "patient",
    title: "my-health-record",
    type: "basic",
    icon: "mat_outline:health_and_safety",
    //link    : '/apps/patient/booking'
    link: "/apps/patient/health-timeline",
  },
];
export const futuristicNavigation: CadNavigationItem[] = [
  {
    id: "patient",
    title: "my-health-record",
    type: "basic",
    icon: "mat_outline:health_and_safety",
    //link    : '/apps/patient/booking'
    link: "/apps/patient/health-timeline",
  },
  {
    id: "donation",
    title: "my-blood-donation",
    type: "basic",
    icon: "heroicons_outline:calendar",
    link: "/apps/donation/dashboard",
  },
];
export const horizontalNavigation: CadNavigationItem[] = [
  {
    id: "patient",
    title: "my-health-record",
    type: "basic",
    icon: "mat_outline:health_and_safety",
    //link    : '/apps/patient/booking',
    link: "/apps/patient/health-timeline",
  },
  {
    id: "donation",
    title: "my-blood-donation",
    type: "basic",
    icon: "heroicons_outline:calendar",
    link: "/apps/donation/dashboard",
  },
  // {
  //   id: "vc-on-demand",
  //   title: "vc-on-demand",
  //   type: "basic",
  //   icon: "videocam",
  //   link: "/apps/vc-on-demand",
  // },
  // {
  //   id: "kkm-portal",
  //   title: "Health Directory",
  //   type: "basic",
  //   icon: "heroicons_outline:newspaper",
  //   link: "/apps/kkm-portal",
  // },
  // {
  //   id: "vc",
  //   title: "Virtual Clinic",
  //   type: "basic",
  //   icon: "videocam",
  //   link: "/apps/vc/vc-example",
  // },
];
