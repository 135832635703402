import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AppConfigService {
  constructor() { }

  public changePreferencePanel(preferencePanel: string) {
    localStorage.setItem("preferencePanel", preferencePanel);
  }

  getPreferencePanel(): string {
    return localStorage.getItem("preferencePanel");
  }
  
  public changeDateTimeFormat(format: string) {
    localStorage.setItem("dateTimeFormat", format);
  }

  getEncounterStatus(): string {
    return localStorage.getItem("encounterStatus");
  }

  getDateTimeFormat(): string {
    return localStorage.hasOwnProperty('dateTimeFormat') ? localStorage.getItem("dateTimeFormat") : null;
  }

  public changeDateFormat(format: string) {
    localStorage.setItem("dateFormat", format);
  }

  getDateFormat(): string {
    return localStorage.hasOwnProperty('dateFormat') ? localStorage.getItem("dateFormat") : null;
  }

  public changeTimezone(timezone: string) {
    localStorage.setItem("timezone", timezone);
  }

  getTimezone(): string {
    return localStorage.hasOwnProperty('timezone') ? localStorage.getItem("timezone") : null;
  }

  public changeLoginId(loginId: string) {
    localStorage.setItem("loginId", loginId);
  }

  getLoginId(): string {
    return localStorage.hasOwnProperty('loginId') ? localStorage.getItem("loginId") : null;
  }

  public changeUserId(userId: string) {
    localStorage.setItem("userId", userId);
  }

  getUserId(): string {
    return localStorage.hasOwnProperty('userId') ? localStorage.getItem("userId") : null;
  }

  public changeUserIdentifier(userIdentifier: string) {
    localStorage.setItem("userIdentifier", userIdentifier);
  }

  getUserIdentifier(): string {
    return localStorage.hasOwnProperty('userIdentifier') ? localStorage.getItem("userIdentifier") : null;
  }

  public changeUserName(userName: string) {
    localStorage.setItem("userName", userName);
  }

  getUserName(): string {
    return localStorage.hasOwnProperty('userName') ? localStorage.getItem("userName") : null;
  }

  public changeInstCode(instCode: string) {
    localStorage.setItem("instCode", instCode);
  }

  getInstCode(): string {
    return localStorage.hasOwnProperty('instCode') ? localStorage.getItem("instCode") : null;
  }

  public changeInstName(instName: string) {
    localStorage.setItem("instName", instName);
  }

  getInstName(): string {
    return localStorage.hasOwnProperty('instName') ? localStorage.getItem("instName") : null;
  }

  public changePatientGender(patientGender: string) {
    localStorage.setItem("patientGender", patientGender);
  }

  getPatientGender(): string {
    return localStorage.getItem("patientGender");
  }


  public changePatientIcNo(patientIcNo: string) {
    localStorage.setItem("patientIcNo", patientIcNo);
  }

  getPatientIcNo(): string {
    return localStorage.getItem("patientIcNo");
  }

  public changePatientResourceId(patientResourceId: string) {
    localStorage.setItem("patientResourceId", patientResourceId);
  }

  getPatientResourceId(): string {
    return localStorage.hasOwnProperty('patientResourceId') ? localStorage.getItem("patientResourceId") : null;
  }

  public changePatientEmail(patientId: string) {
    localStorage.setItem("patientEmail", patientId);
  }

  getPatientEmail(): string {
    return localStorage.hasOwnProperty('patientEmail') ? localStorage.getItem("patientEmail") : null;
  }

  public changePatientId(patientId: string) {
    sessionStorage.setItem("patientId", patientId);
  }

  getPatientId(): string {
    return sessionStorage.hasOwnProperty('patientId') ? localStorage.getItem("patientId") : null;
  }

  public changePatientBirthDate(patientBirthdate: string) {
    localStorage.setItem("patientBirthdate", patientBirthdate);
  }

  getPatientBirthdate(): string {
    return localStorage.getItem("patientBirthdate");
  }

  public patientAge(patientAge: string) {
    localStorage.setItem("patientAge", patientAge);
  }

  getPatientAge(): string {
    return localStorage.getItem("patientAge");
  }

  checkSystemTask(task: string) {
    const element = this.getSystemTask().find((x) => {
      return x === task;
    });
    return typeof element === "undefined" ? false : true;
  }

  public changeSystemTasks(systemTasks: string) {
    localStorage.setItem("systemTasks", systemTasks);
  }

  getSystemTask(): string[] {
    return JSON.parse(localStorage.getItem("systemTasks"));
  }

  getPatientConsent(): string {
    return localStorage.getItem("patientConsent");
  }

  // public changePatientConsent(patientConsent: string) {
  //   localStorage.setItem("patientConsent", patientConsent);
  // }

  // getPatientConsent(): string {
  //   return localStorage.getItem("patientConsent");
  // }

  public changeFacilityCode(facilityCode: string) {
    localStorage.setItem("facilityCode", facilityCode);
  }

  getFacilityCode(): string {
    return localStorage.hasOwnProperty('facilityCode') ? localStorage.getItem("facilityCode") : null;
  }

  public changeFacilityName(facilityName: string) {
    localStorage.setItem("facilityName", facilityName);
  }

  getFacilityName(): string {
    return localStorage.hasOwnProperty('facilityName') ? localStorage.getItem("facilityName") : null;
  }

  public changeMinSession(min: string) {
    localStorage.setItem("minSession", min);
  }

  getMinSession(): number {
    return parseInt(localStorage.getItem("minSession"));
  }

  public changeSecSession(sec: string) {
    localStorage.setItem("secSession", sec);
  }

  getSecSession(): number {
    return parseInt(localStorage.getItem("secSession"));
  }

  public changePractitionerName(practitionerName: string) {
    localStorage.setItem("practitionerName", practitionerName);
  }

  getPractitionerName(): string {
    return localStorage.getItem("practitionerName");
  }

  public changePractitionerRoleId(practitionerRoleId: string) {
    localStorage.setItem("practitionerRoleId", practitionerRoleId);
  }

  getPractitionerRoleId(): string {
    return localStorage.getItem("practitionerRoleId");
  }

  public changeEncounterId(encounterId: string) {
    localStorage.setItem("encounterId", encounterId);
  }

  getEncounterId(): string {
    return localStorage.getItem("encounterId");
  }

  //switchUser
  public changeDefaultPatientResourceId(patientResourceId: string) {
    localStorage.setItem("defaultPatientResourceId", patientResourceId);
  }
  getDefaultPatientResourceId(): string {
    return localStorage.getItem("defaultPatientResourceId");
  }
  public changeDefaultPatientId(value: string) {
    localStorage.setItem("defaultPatientId", value);
  }
  getDefaultPatientId(): string {
    return localStorage.hasOwnProperty('defaultPatientId') ? localStorage.getItem("defaultPatientId") : null;
  }
  public changeIdType(value: string) {
    localStorage.setItem("idType", value);
  }
  getIdType(): string {
    return localStorage.hasOwnProperty('idType') ? localStorage.getItem("idType") : null;
  }
  public changeFullName(value: string) {
    localStorage.setItem("fullName", value);
  }
  getFullName(): string {
    return localStorage.hasOwnProperty('fullName') ? localStorage.getItem("fullName") : null;
  }
  public changePhone(value: string) {
    localStorage.setItem("phone", value);
  }
  getPhone(): string {
    return localStorage.hasOwnProperty('phone') ? localStorage.getItem("phone") : null;
  }
  public changeEmail(value: string) {
    localStorage.setItem("email", value);
  }
  getEmail(): string {
    return localStorage.hasOwnProperty('email') ? localStorage.getItem("email") : null;
  }
  public changeFCMToken(value: string) {
    localStorage.setItem("fcmToken", value);
  }
  getFCMToken(): string {
    return localStorage.hasOwnProperty('fcmToken') ? localStorage.getItem("fcmToken") : null;
  }
  public changeKeyId(value: string) {
    localStorage.setItem("keyId", value);
  }
  getKeyId(): string {
    return localStorage.hasOwnProperty('keyId') ? localStorage.getItem("keyId") : null;
  }


  public DocumentManifestId(documentManifestId: string) {
    localStorage.setItem("Documen Manifest Id", documentManifestId);
  }
  getDocumentManifestId(): string {
    return localStorage.getItem("Documen Manifest Id");
  }


  public changeSessionPatientId(patientId: string) {
    sessionStorage.setItem("patientId", patientId);
  }

  getSessionPatientId(): string {
    return sessionStorage.hasOwnProperty('patientId') ? sessionStorage.getItem("patientId") : null;
  }

  public changePatientMrn(patientMrn: string) {
    localStorage.setItem("patientMrn", patientMrn);
  }

  getPatientMrn(): string {
    return localStorage.getItem("patientMrn");
  }


  public changeCompositionId(compositionId: string) {
    localStorage.setItem("compositionId", compositionId);
  }

  getCompositionId(): string {
    return localStorage.getItem("compositionId");
  }

  public changeServiceCode(serviceCode: string) {
    localStorage.setItem("serviceCode", serviceCode);
  }

  getServiceCode(): string {
    return localStorage.getItem("serviceCode");
  }


  public changeLocationId(locationId: string) {
    localStorage.setItem("locationId", locationId);
  }

  getLocationId(): string {
    return localStorage.getItem("locationId");
  }

  getRoleDisplay(): string {
    return localStorage.getItem("roleDisplay");
  }

  public changeRoleDisplay(data: string) {
    localStorage.setItem("roleDisplay", data);
  }

  public changeEncounterIdentifier(encounterIdentifier: string) {
    localStorage.setItem("encounterIdentifier", encounterIdentifier);
  }

  getEncounterIdentifier(): string {
    return localStorage.getItem("encounterIdentifier");
  }

  public changePractitionerId(practitionerId: string) {
    localStorage.setItem("practitionerId", practitionerId);
  }

  getPractitionerId(): string {
    return localStorage.getItem("practitionerId");
  }


  public changeRoleActive(practitionerId: string) {
    localStorage.setItem("roleActive", practitionerId);
  }

  getRoleActive(): string {
    return localStorage.getItem("roleActive");
  }
}