/* eslint-disable */
export const shortcuts = [
  {
    id: "a1ae91d3-e2cb-459b-9be9-a184694f548b",
    label: "Changed By",
    description: "DR. Billy",
    label2: "Hospital Kuala Lumpur",
    label3: "Reason",
    label4: "Start Medication",
    icon: "heroicons_outline:clipboard-list",
    link: "/docs/changelog",
    useRouter: true,
  },
//   {
//     id: "989ce876-c177-4d71-a749-1953c477f825",
//     label: "Changed By",
//     description: "DR Noryati",
//     label2: "Hospital Kuala Lumpur",
//     label3: "Reason",
//     label4: "Blood Clot Not Resolve",
//     icon: "heroicons_outline:book-open",
//     link: "/docs/guides/getting-started/introduction",
//     useRouter: true,
//   },
//   {
//     id: "2496f42e-2f25-4e34-83d5-3ff9568fd984",
//     label: "Changed By",
//     description: "DR Noryati",
//     label2: "Hospital Kuala Lumpur",
//     label3: "Reason",
//     label4: "Blood Clot Not Resolve",
//     icon: "heroicons_outline:support",
//     link: "/apps/help-center",
//     useRouter: true,
//   },
//   {
//     id: "0a240ab8-e19d-4503-bf68-20013030d526",
//     label: "Changed By",
//     description: "Dr Noryati",
//     label2: "Hospital Kuala Lumpur",
//     label3: "Reason",
//     label4: "Blood Clot Not Resolve",
//     icon: "heroicons_outline:refresh",
//     link: "/dashboards/project",
//     useRouter: false,
//   },
];

export const results = [
    {
        id: "0a240ab8-e19d-4503-bf68-20013030d526",
        label: "Changed By",
        description: "Dr Noryati",
        label2: "Hospital Kuala Lumpur",
        label3: "Reason",
        label4: "Blood Clot Not Resolve",
        icon: "heroicons_outline:refresh",
        link: "/dashboards/project",
        useRouter: false,
      },
];
