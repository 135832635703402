import { NcrConfigService } from "app/ncrconfig.service";
import {
  Subject,
  BehaviorSubject,
  map,
  Observable,
  ReplaySubject,
  switchMap,
  take,
  tap,
} from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FhirConfigService } from "app/fhirconfig.service";
import { FhirPathService } from "app/fhirpath.service";
import { Injectable } from "@angular/core";
import { AppConfigService } from "app/appconfig.service";
import moment, { Moment } from "moment";

@Injectable({
  providedIn: "root",
})
export class AppService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(
    private _httpClient: HttpClient,
    private _ncrConfigService: NcrConfigService,
    private _fhirConfigService: FhirConfigService
  ) {}

  /**
   * Read patient source by id
   */
  readPatientByIdentifier(identifier: string): Observable<any> {
    return this._httpClient.get<fhir.r4.Patient>(
      this._fhirConfigService.getFhirService() +
        "/Patient?identifier=" +
        identifier +
        "&_count=1"
    );
  }
}
