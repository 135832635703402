import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import { MatButton } from "@angular/material/button";
import { Subject, takeUntil } from "rxjs";
import { Notification } from "app/layout/common/notification/notification.type";
import { NotificationsService } from "app/layout/common/notifications/notifications.service";
import { NotificationService } from "app/layout/common/notification/notification.service";
import { FhirPathService } from "app/fhirpath.service";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { AuthService } from "app/core/auth/auth.service";
import { AppConfigService } from "app/appconfig.service";
import { FhirConfigService } from "app/fhirconfig.service";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "notification",
  templateUrl: "./notification.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: "notification",
})
export class NotificationComponent implements OnInit, OnDestroy {
  @ViewChild("notificationsOrigin") private _notificationsOrigin: MatButton;
  @ViewChild("notificationsPanel")
  private _notificationsPanel: TemplateRef<any>;

  notifications: Array<Notification> = [];
  communications: fhir.r4.Communication[];
  appointments: fhir.r4.Appointment[];

  unreadCount: number = 0;
  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _notificationsService: NotificationsService,
    private _notificationService: NotificationService,
    private _fhirConfigService: FhirConfigService,
    private _fhirPathService: FhirPathService,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private _authService: AuthService,
    private _appConfigService: AppConfigService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._notificationService.appointments$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response) => {
        this.appointments = response as fhir.r4.Appointment[];
      });

    this._notificationService.communications$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response) => {
        this.communications = response as fhir.r4.Communication[];

        this.notifications = [];
        for (let i = 0; i < this.communications.length; i++) {
          let _notification = new Notification();
          _notification.id = this.communications[i].id;

          _notification.image = null;
          for (let j = 0; j < this.communications[i].note?.length; j++) {
            if (this.communications[i].note[j]?.authorString == "title")
              _notification.title = this.communications[i].note[j]?.text;
            if (this.communications[i].note[j]?.authorString == "body")
              _notification.description = this.communications[i].note[j]?.text;
            if (this.communications[i].note[j]?.authorString == "category") {
              //Check Category
              if (this.communications[i].note[j]?.text == "vc") {
                _notification.link = "/apps/virtual-clinic/virtual-clinic";
                _notification.icon = "mat_outline:videocam";
              } else if (
                this.communications[i].note[j]?.text == "appointment"
              ) {
                _notification.link = "/apps/inbox";
                _notification.icon = "mat_outline:edit_calendar";
              } else if (this.communications[i].note[j]?.text == "general") {
                _notification.link = "/apps";
                _notification.icon = "mat_outline:document_scanner";
              }
            }
          }
          _notification.time = this.communications[i].meta.lastUpdated;
          if (this.communications[i].status == "in-progress")
            _notification.read = true;
          else _notification.read = false;

          _notification.useRouter = true;
          _notification.resource = this.communications[i];

          let appointmentReference = this.communications[i].payload?.find(
            (x) => x.contentReference?.type === "Appointment"
          ).contentReference?.reference;
          let appointment: fhir.r4.Appointment = this.appointments?.find(
            (x) => "Appointment/" + x.id === appointmentReference
          );
          if (appointment != null) {
            if (appointment.serviceType[0].coding[0].code != "06")
              _notification.link = "/apps/patient/health-appointment";
            else _notification.link = "/apps/donation/blood-appointment";
          }

          if (
            this.communications[i].status == "in-progress" ||
            this.communications[i].status == "preparation"
          )
            this.notifications.push(_notification);
        }
        this._calculateUnreadCount();
        this._changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  openInbox(): void {
    this._router.navigateByUrl("/apps/inbox/list");
  }
  openPanel(): void {
    if (!this._notificationsPanel || !this._notificationsOrigin) {
      return;
    }
    if (!this._overlayRef) {
      this._createOverlay();
    }
    this._overlayRef.attach(
      new TemplatePortal(this._notificationsPanel, this._viewContainerRef)
    );
  }
  closePanel(): void {
    this._overlayRef.detach();
  }
  markAllAsRead(): void {
    let _entries: fhir.r4.BundleEntry[] = [];
    for (let i = 0; i < this.communications.length; i++) {
      this.communications[i].status = "in-progress";
      let _entry = <fhir.r4.BundleEntry>{
        fullUrl:
          this._fhirConfigService.getFhirService() +
          "/Communication/" +
          this.communications[i].id,
        request: {
          method: "PUT",
          url: "Communication/" + this.communications[i].id,
        },
        resource: this.communications[i],
      };
      _entries.push(_entry);
    }
    let _bundle = <fhir.r4.Bundle>{
      resourceType: "Bundle",
      type: "transaction",
      entry: _entries,
    };
    console.log(JSON.stringify(_bundle));
    this._notificationService
      .postBundle(_bundle)
      .subscribe((res) =>
        this._notificationService.getAllCommunications().subscribe()
      );
  }
  toggleRead(notification: Notification): void {
    notification.resource.received = new Date().toISOString().slice(0, 10);
    if (notification.read) {
      notification.resource.status = "preparation";
      notification.read = false;
    } else {
      notification.resource.status = "in-progress";
      notification.read = true;
    }
    this._notificationService
      .updateCommunication(notification.resource)
      .subscribe((res) =>
        this._notificationService.getAllCommunications().subscribe()
      );
  }
  toggleComplete(notification: Notification): void {
    notification.resource.received = new Date().toISOString().slice(0, 10);
    notification.resource.status = "completed";
    this._notificationService
      .updateCommunication(notification.resource)
      .subscribe((res) =>
        this._notificationService.getAllCommunications().subscribe()
      );
  }
  delete(notification: Notification): void {
    notification.resource.status = "completed";
    notification.resource.received = new Date().toISOString().slice(0, 10);
    this._notificationService
      .updateCommunication(notification.resource)
      .subscribe((res) =>
        this._notificationService.getAllCommunications().subscribe()
      );
  }
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
  private _createOverlay(): void {
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: "cad-backdrop-on-mobile",
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(
          this._notificationsOrigin._elementRef.nativeElement
        )
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: "start",
            originY: "bottom",
            overlayX: "start",
            overlayY: "top",
          },
          {
            originX: "start",
            originY: "top",
            overlayX: "start",
            overlayY: "bottom",
          },
          {
            originX: "end",
            originY: "bottom",
            overlayX: "end",
            overlayY: "top",
          },
          {
            originX: "end",
            originY: "top",
            overlayX: "end",
            overlayY: "bottom",
          },
        ]),
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }
  private _calculateUnreadCount(): void {
    let count = 0;
    if (this.notifications && this.notifications.length) {
      count = this.notifications.filter(
        (notification) => !notification.read
      ).length;
    }
    this.unreadCount = count;
  }
}
