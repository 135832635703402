import { AppointmentMockApi } from 'app/mock-api/apps/appointment/api';
import { ProvidersMockApi } from 'app/mock-api/apps/providers/api';
import { PatientMockApi } from 'app/mock-api/apps/patient/api';
import { MapMockApi } from 'app/mock-api/apps/map/api';
import { MessagesMockApi } from 'app/mock-api/common/messages/api';
import { NavigationMockApi } from 'app/mock-api/common/navigation/api';
import { NotificationsMockApi } from 'app/mock-api/common/notifications/api';
import { ShortcutsMockApi } from 'app/mock-api/common/shortcuts/api';
import { UserMockApi } from 'app/mock-api/common/user/api';
import { FacilityMockApi } from 'app/mock-api/apps/facility/api';
import { DonationMockApi } from 'app/mock-api/apps/donation/api';
import { DonationCenterMockApi } from 'app/mock-api/apps/donation-center/api';

export const mockApiServices = [
    AppointmentMockApi,
    ProvidersMockApi,
    PatientMockApi,
    MapMockApi,
    MessagesMockApi,
    NavigationMockApi,
    NotificationsMockApi,
    ShortcutsMockApi,
    UserMockApi,
    FacilityMockApi,
    DonationMockApi,
    DonationCenterMockApi
];
