import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NcrConfigService } from "app/ncrconfig.service";
import { FhirConfigService } from "app/fhirconfig.service";
import { FhirPathService } from "app/fhirpath.service";
import {
  Subject,
  BehaviorSubject,
  map,
  Observable,
  ReplaySubject,
  switchMap,
  take,
  tap,
} from "rxjs";
import { Notification } from "app/layout/common/notification/notification.type";
import { AppConfigService } from "app/appconfig.service";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  private _communications: ReplaySubject<fhir.r4.Communication[]> =
    new ReplaySubject<fhir.r4.Communication[]>(1);
  private _appointments: ReplaySubject<fhir.r4.Appointment[]> =
    new ReplaySubject<fhir.r4.Appointment[]>(1);

  private subject = new Subject<void>();

  fhirHttpOptions = {
    headers: new HttpHeaders({
      "Cache-Control": "no-cache",
      Accept: "application/fhir+json",
    }),
  };
  constructor(
    private _httpClient: HttpClient,
    private _ncrConfigService: NcrConfigService,
    private _fhirConfigService: FhirConfigService,
    private _fhirPathService: FhirPathService,
    private _appConfigService: AppConfigService
  ) {}

  sendClickEvent() {
    this.subject.next();
  }
  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }
  get communications$(): Observable<fhir.r4.Communication[]> {
    return this._communications.asObservable();
  }
  get appointments$(): Observable<fhir.r4.Appointment[]> {
    return this._appointments.asObservable();
  }
  getAllCommunications(): Observable<fhir.r4.Communication[]> {
    let id: string = this._appConfigService.getPatientId();
    return this._httpClient
      .get<fhir.r4.Communication[]>(
        this._fhirConfigService.getFhirService() +
          "/Communication?identifier=GENERAL-QA,GENERAL," +
          id +
          "&_count=400&_sort=-_lastUpdated" +
          "&status=preparation,in-progress" +
          "&_include=Communication:payload-reference",
        this.fhirHttpOptions
      )
      .pipe(
        tap((response) => {
          let letjstring = JSON.stringify(response);
          let jsonObject: fhir.r4.Bundle = JSON.parse(letjstring);

          let communications: fhir.r4.Communication[] =
            this._fhirPathService.evaluate(
              jsonObject,
              "entry.resource.ofType(Communication)"
            );
          this._communications.next(communications);
          let appointments: fhir.r4.Appointment[] =
            this._fhirPathService.evaluate(
              jsonObject,
              "entry.resource.ofType(Appointment)"
            );
          this._appointments.next(appointments);
        })
      );
  }
  updateCommunication(
    communication: fhir.r4.Communication
  ): Observable<fhir.r4.Communication[]> {
    return this._httpClient
      .put<fhir.r4.Communication[]>(
        this._fhirConfigService.getFhirService() +
          "/Communication/" +
          communication.id,
        communication
      )
      .pipe(
        tap((response) => {
          let letjstring = JSON.stringify(response);
          let jsonObject: fhir.r4.Bundle = JSON.parse(letjstring);
          console.log(letjstring);
        })
      );
  }

  postBundle(bundle: fhir.r4.Bundle): Observable<fhir.r4.Bundle> {
    return this._httpClient
      .post<fhir.r4.Bundle>(this._fhirConfigService.getFhirService(), bundle)
      .pipe(
        tap((response) => {
          let letjstring = JSON.stringify(response);
          let jsonObject: fhir.r4.Bundle = JSON.parse(letjstring);
          console.log(letjstring);
        })
      );
  }

  // update(query:fhir.r4.Communication): Observable<fhir.r4.Communication>
  // {
  //     return this.communications$.pipe(
  //         take(1),
  //         switchMap(communications => this._httpClient.put<fhir.r4.Communication>(this._fhirConfigService.getFhirService() +"/Communication/"+query.id,query).pipe(
  //             map((updatedNotification: fhir.r4.Communication) => {

  // 			const index = communications.findIndex(item => item.id === query.id);
  // 			communications[index] = updatedNotification;
  //             this._communications.next(communications);
  //                 return updatedNotification;
  //             })
  //         ))
  //     );
  // }
}
