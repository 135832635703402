import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboards/project'
    { path: '', pathMatch: 'full', redirectTo: '' },

    // Redirect signed in user to the '/dashboards/project'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    // { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'main' },

    // Landing routes
    // {
    //     path: '',
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'empty'
    //     },
    //     children: [
    //         { path: 'patient', loadChildren: () => import('app/modules/person/patient/patient.module').then(m => m.PatientModule) },
    //     ]
    // },

    // Admin routes
    {
        path: '',
        component: LayoutComponent,
        children: [

			{ 	path: 'summary/:id',
				resolve: {
					initialData: InitialDataResolver,
				}, 
				loadChildren: () => import('app/modules/ips/ips.module').then(m => m.IpsModule) 
			},

            // Pages
            {
                path: 'pages', children: [
                    // Coming Soon
                    // { path: 'coming-soon', loadChildren: () => import('app/modules/cad/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule) },

					{ path: 'expires', loadChildren: () => import('app/modules/cad/pages/expires/expires.module').then(m => m.ExpiresModule) },
					{ path: 'enter-password', loadChildren: () => import('app/modules/cad/pages/enter-password/enter-password.module').then(m => m.EnterPasswordModule) },
					{ path: 'invalid-password', loadChildren: () => import('app/modules/cad/pages/invalid-password/invalid-password.module').then(m => m.InvalidPasswordModule) },
					
                    // Error
                    {
                        path: 'error', children: [
                            { path: '404', loadChildren: () => import('app/modules/cad/pages/error/error-404/error-404.module').then(m => m.Error404Module) },
                            { path: '500', loadChildren: () => import('app/modules/cad/pages/error/error-500/error-500.module').then(m => m.Error500Module) }
                        ]
                    },
                    // Maintenance
                    { path: 'maintenance', loadChildren: () => import('app/modules/cad/pages/maintenance/maintenance.module').then(m => m.MaintenanceModule) },
                ]
            },

            // 404 & Catch all
            { path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/cad/pages/error/error-404/error-404.module').then(m => m.Error404Module) },
            { path: '**', redirectTo: '404-not-found' }
        ]
    }
];
