<button mat-icon-button matTooltip="User Profile" [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img class="w-7 h-7 rounded-full" *ngIf="showAvatar" [src]="user.avatar">
        <mat-icon *ngIf="!showAvatar" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <!-- <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span><ng-container *transloco="let t">
                {{ t('signed-in-as') }}
            </ng-container></span>
            <span *ngIf="user" class="mt-1.5 text-md font-medium">{{ user.name }}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="goToProfile()">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span><ng-container *transloco="let t">
            {{ t('kkmonelogin-console') }}
        </ng-container></span>
    </button>
    <button mat-menu-item (click)="goToConsent()">
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span><ng-container *transloco="let t">
            {{ t('privacy-consent') }}
        </ng-container></span>
    </button>
    <button mat-menu-item (click)="goToRelatedPerson()">
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span><ng-container *transloco="let t">
            {{ t('related-person') }}
        </ng-container></span>
    </button>
    <mat-divider class="my-2"></mat-divider> -->
    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span><ng-container *transloco="let t">
                {{ t('sign-out') }}
            </ng-container></span>
    </button>
</mat-menu>