import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { MessagesService } from 'app/layout/common/messages/messages.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { ShortcutsService } from 'app/layout/common/shortcuts/shortcuts.service';
import { IpsService } from './modules/ips/ips.service';
import moment from 'moment';
import { IPSRequest } from './modules/ips/ips-record';

// export class IPSRequestData {

// 	attributes: {
// 		approvedBy: string;
// 		bundleId: string;
// 		createdBy: string;
// 		createdDate: string;
// 		expEnd: string;
// 		expStart: string;
// 		identifier: string;
// 		password: string;
// 		patientId: string;
// 		requestStatus: string;
// 		smartLink: string;
// 		sourceSystem: string;
// 		updateBy: string;
// 		updateDate: string
// 	}

// 	constructor(ipsRequestData: IPSRequestData) {
// 		Object.assign(this, ipsRequestData);
// 	}
// }

// export class IPSRequest {
// 	data: IPSRequestData[];
// 	meta: {
// 		number: number,
// 		totalRecords: number,
// 		limit: number,
// 		totalPages: number
// 	}

// 	constructor(ipsRequest: IPSRequest) {
// 		Object.assign(this, ipsRequest);
// 	}
// }

@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(
        // private _messagesService: MessagesService,
        // private _navigationService: NavigationService,
        // private _notificationsService: NotificationsService,
        // private _shortcutsService: ShortcutsService,
		private _ipsService: IpsService,
		private router: Router
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Use this resolver to resolve initial mock-api for the application
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

		// Check if given data in link entered
		let id = route.params.id;

		// get cad server
		this._ipsService.getIpsRecordByBundleId(id)
		.pipe().subscribe((response: IPSRequest) => {
			console.log("getIpsRecordByBundleId ", response);

			// let response: IPSRequest = {
			// 	"data": [
			// 		{
			// 			"type": "IPSRequest",
			// 			"id": "43",
			// 			"attributes": {
			// 				"approvedBy": "9a0e19a3-e892-4b9d-824e-627efe399d81",
			// 				"bundleId": "da8fae1b-9f4b-417f-a6bc-47d7becfdb43",
			// 				"createdBy": "9a0e19a3-e892-4b9d-824e-627efe399d81",
			// 				"createdDate": null,
			// 				"expEnd": "2024-03-22T11:52Z",
			// 				"expStart": "2024-01-22T11:52Z",
			// 				"identifier": "920510146671",
			// 				"password": "123456",
			// 				"patientId": "5a6a0bc3-2c32-4e01-bcb4-56ddcb4d34c7",
			// 				"requestStatus": "APPROVED",
			// 				"smartLink": "https://ips-viewer.mhnexus.com/summary/da8fae1b-9f4b-417f-a6bc-47d7becfdb43",
			// 				"sourceSystem": "VEINS",
			// 				"updateBy": "9a0e19a3-e892-4b9d-824e-627efe399d81",
			// 				"updateDate": null
			// 			}
			// 		}
			// 	],
			// 	"meta": {
			// 		"page": {
			// 			"number": 1,
			// 			"totalRecords": 1,
			// 			"limit": 6,
			// 			"totalPages": 1
			// 		}
			// 	}
			// }

			this._ipsService.setIpsRecord(response);

			if(response.data.length > 0) {

				// check link expired date
				if(response.data[0].attributes.expEnd) {
					let today = moment().format("YYYY-MM-DD HH:mm");

					// remove 
					let end = response.data[0].attributes.expEnd.replace("Z", "");
					let expEnd = moment(end).format("YYYY-MM-DD HH:mm");

					if(moment(expEnd).isAfter(moment(today))) {

						let accessSummary = this._ipsService.getAccessSummary();
						// check password
						if(response.data[0].attributes.password && !accessSummary) {
							this.router.navigate(['/pages/enter-password']);
							return false;
						}
						else {
							this._ipsService.getBundle(response.data[0].attributes.bundleId);
							return true;
						}
					} else {
						this.router.navigate(['/pages/expires']);
					}
				} else {
					this._ipsService.getBundle(response.data[0].attributes.bundleId);
				}

			} else {
				this.router.navigate(['/pages/404-not-found']);
			}
		});
			

		// if(encryptedDetails) {
		// 	let decryptDetails: any = JSON.parse(atob(encryptedDetails));
		// 	// console.log("decrypt ", decryptDetails);

		// 	this._ipsService.getIpsRecordById(decryptDetails.id)
		// 	.pipe().subscribe((response) => {
		// 		console.log("getIpsRecordById ", response);
		// 	})

		// 	// check link expired date
		// 	let isExpEndDate = moment.isDate(decryptDetails.expEnd);
		// 	if(isExpEndDate) {
		// 		let today = moment();
		// 		let expEnd = moment(decryptDetails.expEnd);
			
		// 		if(expEnd.isAfter(today)) {
		// 			this._ipsService.getBundle(decryptDetails.id);
		// 		} else {
		// 			this.router.navigate(['/pages/expires']);
		// 		}
		// 	} else {
		// 		this._ipsService.getBundle(decryptDetails.id);
		// 	}
		// 	return of(true);
		// } else {
		// 	this.router.navigate(['/pages/404-not-found']);
		// }

		// return of(true);
    }
}
