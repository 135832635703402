import { ModuleWithProviders, NgModule } from '@angular/core';
import { CadConfigService } from '@cad/services/config/config.service';
import { CAD_APP_CONFIG } from '@cad/services/config/config.constants';
import { FhirConfigService } from 'app/fhirconfig.service';
import { NcrConfigService } from 'app/ncrconfig.service';
import { environment } from "environments/environment";

@NgModule()
export class CadConfigModule {
    /**
     * Constructor
     */
    constructor(private _cadConfigService: CadConfigService,
        private _fhirConfigService: FhirConfigService,
        private _ncrConfigService: NcrConfigService) {

        _fhirConfigService.changeFhirService(environment.fhirURL);
		_ncrConfigService.changeNcrService(environment.apiURL);
    }

    /**
     * forRoot method for setting user configuration
     *
     * @param config
     */
    static forRoot(config: any): ModuleWithProviders<CadConfigModule> {
        return {
            ngModule: CadConfigModule,
            providers: [
                {
                    provide: CAD_APP_CONFIG,
                    useValue: config
                }
            ]
        };
    }
}
