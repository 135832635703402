<!-- Loading bar -->
<cad-loading-bar></cad-loading-bar>

<!-- Navigation -->
<!-- <ng-container *ngIf="isScreenSmall">
    <cad-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="'over'" [name]="'mainNavigation'"
        [navigation]="navigation.default" [opened]="false">
        <ng-container cadVerticalNavigationContentHeader>
            <div class="flex items-center h-20 pt-6 px-8">
                <img class="w-20" src="assets/images/logo/jata.png" alt="Logo image">
            </div>
        </ng-container>
    </cad-vertical-navigation>
</ng-container> -->

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <ng-container *ngIf="!isScreenSmall">

			<!-- Logo -->
            <div class="flex items-center mx-2 lg:mr-8">
                <div class="hidden lg:flex">
                    <img class="dark:hidden w-20" src="assets/images/logo/jata.png">
                    <img class="hidden dark:flex w-20" src="assets/images/logo/jata.png">
                </div>
                <img class="flex lg:hidden w-8" src="assets/images/logo/jata.png">
            </div>

			<div class="flex items-center mx-2 lg:mr-8">
				<b>International Patient Summary</b>
			</div>

            <!-- <cad-horizontal-navigation class="mr-2" [name]="'mainNavigation'" [navigation]="navigation.horizontal">
            </cad-horizontal-navigation> -->
        </ng-container>
        <!-- <ng-container *ngIf="isScreenSmall">
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
        </ng-container> -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!-- <div [hidden]="checkEnvironment()">
                <div *ngIf="vcAvailable">
                    <button (click)="goToVc()">
                        <igx-icon family="material" [style.color]="'green'" [igxTooltipTarget]="iconTooltip">
                            video_call</igx-icon>
                    </button>
                    <span>1</span>
                    <div class="iconTooltip" #iconTooltip="tooltip" igxTooltip>
                        <div class="iconTooltipContent">
                            <div>
                                <div>You have VC on going</div> <br>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div [hidden]="checkEnvironment()">
                <div *ngIf="!vcAvailable">
                    <igx-icon family="material" [igxTooltipTarget]="iconTooltip">
                        video_call</igx-icon>
                    <div class="iconTooltip" #iconTooltip="tooltip" igxTooltip>
                        <div class="iconTooltipContent">
                            <div>
                                <div>No VC at the moment</div> <br>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <languages></languages>
            <!-- <cad-fullscreen class="hidden md:block"></cad-fullscreen> -->
            <!-- <notification></notification> -->
            <!-- <user></user> -->
            <!-- <span class="font-medium">{{ username }}</span> -->
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full">
        <banner></banner>
        <router-outlet *ngIf="true"></router-outlet>
        <igx-dialog #alert title="Are you still there?" message="If not, we'll close this session in 15 seconds"
            leftButtonLabel="OK" (leftButtonSelect)="alert.close()">
        </igx-dialog>
    </div>

    <!-- Footer -->
    <div
        class="relative flex flex-0 items-center w-full h-10 sm:h-10 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary pr-10" *ngIf="!isScreenSmall">International Patient Summary &copy;
            {{currentYear}}</span>
    </div>

</div>