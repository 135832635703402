import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { User } from 'app/core/user/user.types';
import { AuthService } from 'app/core/auth/auth.service';
import { KeycloakProfile } from 'keycloak-js';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';
import { AppConfigService } from 'app/appconfig.service';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user'
})
export class UserComponent implements OnInit {

  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() showAvatar: boolean = false;
  user: User;
  patientId: string;
  ncrUser: any;

  /**
   * Constructor
   */
  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _keycloakService: KeycloakService,
    private _appConfigService: AppConfigService,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  async ngOnInit(): Promise<void> {
    const userProfile: KeycloakProfile = await this._authService.loadUserProfile();
    this.user = {
      avatar: "",
      email: userProfile.email,
      name: "EHR",
      id: userProfile.id,
      status: "online"
    }


    // if (environment.environmentName === "QA" || environment.environmentName === "STAGING") {
    //   Sentry.setUser({ username: userProfile.username });
    // }
    // this._appConfigService.changeServiceCode(this.ncrUser.serviceCode);

    // this._appConfigService.changeUserName(userProfile.firstName);
    // this._appConfigService.changePatientEmail(userProfile.email);
    // this._appConfigService.changeFullName(userProfile.firstName+" "+userProfile.lastName);
    // this._appConfigService.changeEmail(userProfile.email);

    // if (userProfile["attributes"].patientResourceId) {
    //   this._appConfigService.changePatientResourceId(userProfile["attributes"].patientResourceId[0]);
    //   this._appConfigService.changeDefaultPatientResourceId(userProfile["attributes"].patientResourceId[0]);
    // }
    // if (userProfile["attributes"].idNo) {
    //   this._appConfigService.changePatientId(userProfile["attributes"].idNo[0]);
    //   this._appConfigService.changeDefaultPatientId(userProfile["attributes"].idNo[0]);
    // }
    // if (userProfile["attributes"].idType) {
    //   this._appConfigService.changeIdType(userProfile["attributes"].idType[0]);
    // }
    // if (userProfile["attributes"].mobileNo) {
    //   this._appConfigService.changePhone(userProfile["attributes"].mobileNo[0]);
    // }
    // if (userProfile.email) {
    //   this._appConfigService.changeEmail(userProfile.email);
    // }
    // if (userProfile.firstName&&userProfile.lastName) {
    //   this._appConfigService.changeFullName(userProfile.firstName+" "+userProfile.lastName);
    // }

    this._keycloakService.keycloakEvents$.subscribe({
      next: (e) => {
        if (e.type === KeycloakEventType.OnTokenExpired) {
          this._keycloakService.updateToken(10).then(function (refreshed) {
            if (refreshed) {
              console.log("Token was successfully refreshed");
            } else {
              console.log("Token is still valid");
            }
          })
            .catch(async function () {
              console.log("Failed to refresh the token, or the session has expired");
              if (environment.environmentName === "PRODUCTION") {
                await this._keycloakService.login({
                  redirectUri: window.location.origin + "/portal",
                });
              } else {
                await this._keycloakService.login({
                  redirectUri: window.location.origin,
                });
              }
            });
        }
      }
    });

    this._keycloakService.keycloakEvents$.subscribe({
      next: async (e) => {
        if (e.type === KeycloakEventType.OnAuthRefreshError || e.type === KeycloakEventType.OnAuthLogout || e.type === KeycloakEventType.OnAuthError) {
          console.log("Failed to refresh the token, or the session has expired");
          if (environment.environmentName === "PRODUCTION") {
            await this._keycloakService.login({
              redirectUri: window.location.origin + "/portal",
            });
          } else {
            await this._keycloakService.login({
              redirectUri: window.location.origin,
            });
          }
        }
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign out
   */

  signOut(): void {
    if (environment.environmentName === "PRODUCTION") {
      this._authService.logout("/portal");
    } else {
      this._authService.logout("");
    } localStorage.clear();
  }

  goToConsent(): void {
    this._router.navigate(['apps/consent']);
  }
  goToRelatedPerson(): void {
    this._router.navigate(['apps/related-person']);
  }

  goToProfile(): void {
    this._authService.redirectToProfile();
  }
}
