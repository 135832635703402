import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'app/appconfig.service';
import { FhirConfigService } from 'app/fhirconfig.service';
import { FhirPathService } from 'app/fhirpath.service';
import { NcrConfigService } from 'app/ncrconfig.service';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';
import { Observable, take } from 'rxjs';

@Injectable()
export class AuthService {
  restURL: string;

  constructor(
    private _httpClient: HttpClient,
    private keycloakService: KeycloakService,
    private _fhirConfigService: FhirConfigService,
    private _appConfigService: AppConfigService,
    private _ncrConfigService: NcrConfigService,
    private _fhirPathService: FhirPathService,
  ) {
    this.restURL = this._ncrConfigService.getNcrService() + "/api/v1";

  }

  public getLoggedUser(): KeycloakTokenParsed | undefined {
    try {
      const userDetails: KeycloakTokenParsed | undefined = this.keycloakService.getKeycloakInstance().idTokenParsed;
      return userDetails;
    } catch (e) {
      console.error("Exception", e);
      return undefined;
    }
  }

  public isLoggedIn(): Promise<boolean> {
    return this.keycloakService.isLoggedIn();
  }

  public loadUserProfile(): Promise<KeycloakProfile> {
    return this.keycloakService.loadUserProfile();
  }

  public login(): void {
    this.keycloakService.login();
  }


  // public logout(): void {
  //   this.keycloakService.logout(window.location.origin);
  // }

  public logout(path): void {
    this.keycloakService.logout(window.location.origin + path);
  }

  public redirectToProfile(): void {
    this.keycloakService.getKeycloakInstance().accountManagement();
  }

  public getRoles(): string[] {
    return this.keycloakService.getUserRoles();
  }

  public clearToken(): void {
    return this.keycloakService.clearToken();
  }

  public accessToken(): string {
    return this.keycloakService.getKeycloakInstance().token;
  }

  public refreshToken(): string {
    return this.keycloakService.getKeycloakInstance().refreshToken;
  }

  getLatestEncounter() {
    return this._httpClient.get(this._fhirConfigService.getFhirService() + "/Encounter?subject=" + this._appConfigService.getPatientResourceId() + "&_sort=-date&_count=1").pipe(
      take(1)
    );
  }

  getNcrUserProfile(keycloakId): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set("keycloakId", keycloakId);
    return this._httpClient.post(this.restURL + `/user/ncr-user-profile`, httpParams);
  }
  getPractitioner(icNo: string): Observable<any> {
    return this._httpClient.get<fhir.r4.Bundle>(
      this._fhirConfigService.getFhirService() + "/Practitioner?identifier=http://fhir.hie.moh.gov.id/sid/national-id|" + icNo
    );
  }

  getPractitionerRole(practitionerRoleId): Observable<any> {
    return this._httpClient.get<fhir.r4.PractitionerRole>(
      this._fhirConfigService.getFhirService() + "/PractitionerRole/" + practitionerRoleId
    );
  }

  getPractitionerId(bundle: fhir.r4.Bundle) {
    let practitioner = this._fhirPathService.evaluate(
      bundle,
      "entry.resource.ofType(Practitioner)"
    );
    return this._fhirPathService.evaluateToString(practitioner[0], "id");
  }
}