import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from "@angular/core";
import { FhirPathService } from "app/fhirpath.service";
import { AppConfigService } from "app/appconfig.service";
import { Subject, takeUntil } from "rxjs";
import { AppService } from "app/app.service";

@Component({
  selector: "banner",
  templateUrl: "./banner.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class BannerComponent {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  user: any = "";
  patient: fhir.r4.Patient;
  relatedPersons: fhir.r4.RelatedPerson[];
  fcmToken: string = null;

  constructor(
    private _appConfigService: AppConfigService,
    private _appService: AppService
  ) {}
  ngOnInit() {
    // this._appService.fcmtoken$
    //   .pipe(takeUntil(this._unsubscribeAll))
    //   .subscribe((response) => {
    //     this.fcmToken =response;
    //   });

    // console.log("token" + this.fcmToken);
    // this._relatedPersonService.banner$
    //   .pipe(takeUntil(this._unsubscribeAll))
    //   .subscribe((response) => {
    //     this.patient = response as fhir.r4.Patient;
    //     if (
    //       this._appConfigService.getDefaultPatientId() !=
    //       this._appConfigService.getPatientId()
    //     )
    //       this.user = this.patient.name[0].text;
    //     else this.user = null;
    //   });
  }
}
