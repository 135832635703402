import { FormsModule } from "@angular/forms";
import { MbscModule } from "@mobiscroll/angular";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  ExtraOptions,
  PreloadAllModules,
  Router,
  RouterModule,
} from "@angular/router";
import { CadModule } from "@cad";
import { CadConfigModule } from "@cad/services/config";
import { CadMockApiModule } from "@cad/lib/mock-api";
import { CoreModule } from "app/core/core.module";
import { appConfig } from "app/core/config/app.config";
import { mockApiServices } from "app/mock-api";
import { LayoutModule } from "app/layout/layout.module";
import { AppComponent } from "app/app.component";
import { appRoutes } from "app/app.routing";
import {
  DisplayDensityToken,
  DisplayDensity,
} from "@infragistics/igniteui-angular";
import { environment } from "../environments/environment";
import { AuthModule } from "app/core/auth/auth.module";
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import { DatePipe } from "@angular/common";
import { IpsComponent } from "./modules/ips/ips.component";
import { SharedModule } from "./shared/shared.module";

// import { initializeApp } from "firebase/app";
// initializeApp(environment.firebase);

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: "enabled",
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    // Cad, CadConfig & CadMockAPI
    CadModule,
    CadConfigModule.forRoot(appConfig),
    CadMockApiModule.forRoot(mockApiServices),

    FormsModule,
    MbscModule,
	// SharedModule,

    // Core module of your application
    CoreModule,

    // Layout module of your application
    LayoutModule,
    AuthModule,
  ],
  providers: [
    DatePipe,
    {
      provide: DisplayDensityToken,
      useValue: { displayDensity: DisplayDensity.cosy },
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
