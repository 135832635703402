import { environment } from "environments/environment";
import { FhirConfigService } from "./fhirconfig.service";
import { NcrConfigService } from "./ncrconfig.service";
import { Component } from "@angular/core";
import { AuthService } from "app/core/auth/auth.service";
import { AppService } from "./app.service";
import { Subject, lastValueFrom } from "rxjs";
import { AppConfigService } from "app/appconfig.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FhirPathService } from "./fhirpath.service";
import { KeycloakProfile } from "keycloak-js";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  ncrUser: any;

  title = "af-notification";
  message: any = null;
  devices: any[];
  fcmtoken: any;
  patientId: string;

//   async ngOnInit(): Promise<void> {
    //930130142014
    // const userProfile: KeycloakProfile = await this._authService.loadUserProfile();

    // let ncrUser$;
    // ncrUser$ = this._authService.getNcrUserProfile(userProfile.id);
    // this.ncrUser = await lastValueFrom(ncrUser$);
    // this._appConfigService.changePractitionerName(this.ncrUser.practitionerName);
    // this._appConfigService.changeRoleDisplay(this.ncrUser.roleDisplay);
    // this._appConfigService.changeInstCode(this.ncrUser.instCode);
    // this._appConfigService.changeLocationId(this.ncrUser.locationId);
    // this._appConfigService.changeServiceCode(this.ncrUser.serviceCode);
    // if (this.ncrUser.practitionerId) {
    //   this._appConfigService.changePractitionerId(this.ncrUser.practitionerId);
    // } else {
    //   this._authService.getPractitioner(userProfile.username).subscribe((res) => {
    //     this._appConfigService.changePractitionerId(this._authService.getPractitionerId(res));
    //   });
    // }
    // this._appConfigService.changePractitionerRoleId(this.ncrUser.practitionerRoleId);
    // if (this.ncrUser.practitionerRoleId) {
    //   const cleanId = this.ncrUser.practitionerRoleId.split("/")[0];
    //   this._authService.getPractitionerRole(cleanId).subscribe((res) => {
    //     this._appConfigService.changeRoleActive(res.active);
    //   });
    // } else {
    //   //nothing yet
    // }

    // this._activeRoute.queryParams.subscribe((params) => {
    //   // this._appConfigService.changePatientResourceId(null);
    //   // this._appConfigService.changePatientId(null);
    //   let patid = params.patientId;
    //   // let patid = "971111114445";
    //   // let patid = "910503145678";
    //   this._appService.readPatientByIdentifier(patid).subscribe({
    //     next: (resp) => {
    //       let patient = this._fhirPathService.evaluate(
    //         resp,
    //         "entry.resource.ofType(Patient)"
    //       );
    //       let gender = this._fhirPathService.evaluateToString(patient[0], "gender");
    //       let birthDate = this._fhirPathService.evaluateToString(patient[0], "birthDate");
    //       // let id = this._fhirPathService.evaluateToString(patid, "id");
    //       let id = this._fhirPathService.evaluateToString(patient[0], "id");
    //       let patientMrn = this._fhirPathService.evaluateToString(
    //         patient[0],
    //         "identifier.where(system='http://fhir.hie.moh.gov.id/sid/patient-mrn').value"
    //       );

    //       if (id !== null && id !== undefined) {
    //         this._appConfigService.changePatientBirthDate(birthDate);
    //         this._appConfigService.changePatientResourceId(id);
    //         this._appConfigService.changeSessionPatientId(id);
    //         this._appConfigService.changePatientId(id);
    //         this._appConfigService.changePatientMrn(patientMrn);
    //         for (let i = 0; i < patient[0].identifier.length; i++) {
    //           if (patient[0].identifier[i].system == "http://fhir.hie.moh.gov.id/sid/national-id") {
    //             this._appConfigService.changePatientIcNo(patient[0].identifier[i].value);
    //           }
    //         } this._appConfigService.changePatientGender(gender);
    //         this._router.navigateByUrl("/apps/patient/health-timeline");
    //       } else if (this._appConfigService.getSessionPatientId() != null && this._appConfigService.getSessionPatientId() != undefined) {
    //         this._router.navigateByUrl("/apps/patient/health-timeline");
    //       }
    //       else {
    //         // this._appConfigService.changePatientResourceId(null);
    //         // this._appConfigService.changePatientId(null);
    //         this._router.navigateByUrl("/apps/home");
    //       }
    //     },
    //   });
    // });


//   }

  //private tabWasClosed = false;//
//   private accessToken: string;
//   private refreshToken: string;

  //@HostListener('window:beforeunload', ['$event'])
//   doBeforeUnload() {
//     //if (document.visibilityState === 'hidden') {
//     this.accessToken = this._authService.accessToken();
//     this.refreshToken = this._authService.refreshToken();

//     //this.tabWasClosed = true;
//     //}
//     //return false;
//   }

  //@HostListener('window:unload', ['$event'])
//   doUnload() {
//     //if (this.tabWasClosed) {
//     if (environment.production) {
//       this.checkHiddenDocument();
//     }
//     //}
//   }

//   private checkHiddenDocument() {
//     if (!navigator.sendBeacon) return true;

//     var data = {
//       id: environment.keycloak.clientId,
//       accessToken: this.accessToken,
//       refreshToken: this.refreshToken,
//     };
//     let headers = {
//       type: "application/json",
//     };
//     let blob = new Blob([JSON.stringify(data)], headers);
//     this._authService.clearToken();

//     navigator.sendBeacon(
//       this._ncrConfigService.getNcrService() + "/api/v1/auth/logout",
//       blob
//     );
//   }

  /**
   * Constructor
   */
  constructor(
    private _fhirConfigService: FhirConfigService,
    private _ncrConfigService: NcrConfigService,
    private _authService: AuthService,
    private _appService: AppService,
    private _appConfigService: AppConfigService,
    private _activeRoute: ActivatedRoute,
    private _fhirPathService: FhirPathService,
    private _router: Router
  ) {


    if (environment.production) {
      console.warn(`🚨 Console output is disabled on production!`);
      console.log = function (): void { };
      console.debug = function (): void { };
      console.warn = function (): void { };
      console.info = function (): void { };
    }

    this._fhirConfigService.changeFhirService(environment.fhirURL);
    this._ncrConfigService.changeNcrService(environment.apiURL);

    window.localStorage.setItem("read_url", environment.apiURL);
  }
}
