import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, ReplaySubject, tap, BehaviorSubject, take } from "rxjs";
import { Navigation } from "app/core/navigation/navigation.types";
import { NcrConfigService } from "app/ncrconfig.service";
import { AppConfigService } from "app/appconfig.service";
import { environment } from "environments/environment";
import { FhirConfigService } from "app/fhirconfig.service";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  restURL: string;
  private _navigation: ReplaySubject<Navigation> =
    new ReplaySubject<Navigation>(1);
  private _vcAvailable: BehaviorSubject<any> = new BehaviorSubject(null);
  private patientSelectedSource = new BehaviorSubject<Boolean>(false);

  patientSelected = this.patientSelectedSource.asObservable();
  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _ncrConfigService: NcrConfigService,
    private _appConfigService: AppConfigService,
    private _fhirConfigService: FhirConfigService,
  ) {
    this.restURL = this._ncrConfigService.getNcrService() + "/api/v1";
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  get vcAvailable$(): Observable<any> {
    return this._vcAvailable.asObservable();
  }

  vcAvailableSet(item) {
    return this._vcAvailable.next(item);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  changePatientSelection(patientSelected: Boolean) {
    this.patientSelectedSource.next(patientSelected);
  }

  /**
   * Get all navigation data
   */
  get(): Observable<Navigation> {
    return this._httpClient.get<Navigation>("api/common/navigation").pipe(
      tap((navigation) => {
        this._navigation.next(navigation);
      })
    );
  }

  getSessionTimer2(): Observable<any> {
    return this._httpClient.get(this.restURL + "/user/get-person-session").pipe(
      tap((response: any) => {
        if (response) {
          this._appConfigService.changeMinSession(response[0].parameterValue);
          this._appConfigService.changeSecSession(response[1].parameterValue);
        }
      })
    );
  }

  getSessionTimer(): Observable<any> {
    const query = `
        {
            AFCModpara(filter:"parameterId == 'HIE_PERSON_SESSION_MIN' , parameterId == 'HIE_PERSON_SESSION_SEC'"){
                edges{
                    node{
                        parameterId
                        parameterValue
                    }
                }
            }
        }`;

    return this._httpClient
      .post<any>(
        this._ncrConfigService.getNcrService() + "/graphql/api/v1",
        JSON.stringify({ query }),
        httpOptions
      )
      .pipe(
        tap((response: any) => {
          response.data.AFCModpara.edges.forEach((element) => {
            if (element.node.parameterId.includes("MIN")) {
              this._appConfigService.changeMinSession(
                element.node.parameterValue
              );
            } else {
              this._appConfigService.changeSecSession(
                element.node.parameterValue
              );
            }
          });
        })
      );
  }
}