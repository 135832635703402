import { formatDate } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FilteringExpressionsTree, FilteringLogic } from "@infragistics/igniteui-angular";
import { FhirConfigService } from "app/fhirconfig.service";
import { NcrConfigService } from "app/ncrconfig.service";
import { BehaviorSubject, Observable, map, take } from "rxjs";
import { IPSRequest } from "./ips-record";

const fhirHttpOptions = {
	headers: new HttpHeaders({
		"cache-control": "no-cache",
		Accept: "application/fhir+json",
	}),
};

const restHttpOptions = {
	headers: new HttpHeaders({
	  "Content-Type": "application/vnd.api+json",
	  "Access-Control-Allow-Origin": "*",
	  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
	  "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization"
	}),
  };

@Injectable({
	providedIn: "root",
})
export class IpsService {

	private _bundle: BehaviorSubject<fhir.r4.Bundle> = new BehaviorSubject(null);
	public bundle$: Observable<fhir.r4.Bundle> = this._bundle.asObservable();

	private _ipsRecord: BehaviorSubject<IPSRequest> = new BehaviorSubject(null);
	public ipsRecord$: Observable<IPSRequest> = this._ipsRecord.asObservable();

	private _accessSummary: BehaviorSubject<Boolean> = new BehaviorSubject(false);
	public accessSummary$: Observable<Boolean> = this._accessSummary.asObservable();

	// private _resources: BehaviorSubject<fhir.r4.Bundle> = new BehaviorSubject(null);
	// public resources$: Observable<fhir.r4.Bundle> = this._resources.asObservable();

	/**
	 * Constructor
	 */
	constructor(
		private _httpClient: HttpClient,
		private _fhirConfigService: FhirConfigService,
		private _ncrConfigService: NcrConfigService,
	) {}

	// -----------------------------------------------------------------------------------------------------
	// @ Public Methods
	// -----------------------------------------------------------------------------------------------------
	
	getBundle(bundleId): any{
		return this._httpClient.get(
			this._fhirConfigService.getFhirService() + "/Bundle/" + bundleId,
		).pipe(
			map((response: fhir.r4.Bundle) =>{
				this._bundle.next(response);
				return response;
			})
		).subscribe();
	}

	getIpsRecordByBundleId(id: string) {
		return this._httpClient
			.get(this._ncrConfigService.getNcrService() + 
				"/api/v1/ips/find-custom",
				{
					params: {
						filter: "bundleId==" + id,
						sort: "bundleId",
						offset: 0,
						limit: 1,
						total: 1,
					}
				},
			).pipe(take(1));
	}

	setIpsRecord(response: IPSRequest) {
		this._ipsRecord.next(response);
	}

	setAccessSummary(isAccess: boolean) {
		this._accessSummary.next(isAccess);
	}

	getAccessSummary() {
		return this._accessSummary.getValue();
	}

	getIpsRecord() {
		return this._ipsRecord.getValue();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Private Methods
	// -----------------------------------------------------------------------------------------------------

}