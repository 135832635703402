import { Injectable } from '@angular/core';
import { from, map } from 'rxjs';
import { assign, cloneDeep } from 'lodash-es';
import { CadMockApiService, CadMockApiUtils } from '@cad/lib/mock-api';
import { providers as providersData, 
        countries as countriesData, 
        tags as tagsData, 
        problems as problemsData,
        filters as filtersData,
        folders as foldersData,
        labels as labelsData,
        mails as mailsData,   } from 'app/mock-api/apps/providers/data';

@Injectable({
    providedIn: 'root'
})
export class ProvidersMockApi
{
    private _providers: any[] = providersData;
    private _countries: any[] = countriesData;
    private _tags: any[] = tagsData;

    private _problems: any[] = problemsData;
    private _filters: any[] = filtersData;
    private _folders: any[] = foldersData;
    private _labels: any[] = labelsData;
    private _mails: any[] = mailsData;

    /**
     * Constructor
     */
    constructor(private _cadMockApiService: CadMockApiService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Providers - GET
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onGet('api/apps/providers/all')
            .reply(() => {

                // Clone the providers
                const providers = cloneDeep(this._providers);

                // Sort the providers by the name field by default
                providers.sort((a, b) => a.name.localeCompare(b.name));

                // Return the response
                return [200, providers];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Providers Search - GET
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onGet('api/apps/providers/search')
            .reply(({request}) => {

                // Get the search query
                const query = request.params.get('query');

                // Clone the providers
                let providers = cloneDeep(this._providers);

                // If the query exists...
                if ( query )
                {
                    // Filter the providers
                    providers = providers.filter(contact => contact.name && contact.name.toLowerCase().includes(query.toLowerCase()));
                }

                // Sort the providers by the name field by default
                providers.sort((a, b) => a.name.localeCompare(b.name));

                // Return the response
                return [200, providers];
            });

         // -----------------------------------------------------------------------------------------------------
        // @ Problems - GET
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
        .onGet('api/apps/providers/problems', 625)
        .reply(({request}) => {

            // Clone the problems mock-api to prevent accidental mock-api updates
            let problems: any[] | null = cloneDeep(this._problems);

            // Paginate - Start
            const problemsLength = problems.length;
            const resultsPerPage = 10;

            // Get the requested page number
            const page = parseInt(request.params.get('page') ?? '1', 10);

            // Calculate pagination details
            const begin = (page - 1) * resultsPerPage;
            const end = Math.min((resultsPerPage * page), problemsLength);
            const lastPage = Math.max(Math.ceil(problemsLength / resultsPerPage), 1);

            // Prepare the pagination object
            let pagination = {};

            // If the requested page number is bigger than
            // the last possible page number, return null for
            // problems but also send the last possible page so
            // the app can navigate to there
            if ( page > lastPage )
            {
                problems = null;
                pagination = {
                    lastPage
                };
            }
            else
            {
                // Paginate the results by 10
                problems = problems.slice(begin, end);

                // Prepare the pagination mock-api
                pagination = {
                    totalResults  : problemsLength,
                    resultsPerPage: resultsPerPage,
                    currentPage   : page,
                    lastPage      : lastPage,
                    startIndex    : begin,
                    endIndex      : end - 1
                };
            }

            // Return the response
            return [
                200,
                {
                    problems,
                    pagination
                }
            ];
        });


        // -----------------------------------------------------------------------------------------------------
        // @ Problem - GET
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
        .onGet('api/apps/providers/problem')
        .reply(({request}) => {

            // Get the id from the params
            const id = request.params.get('id');

            // Clone the problems mock-api to prevent accidental mock-api updates
            const problems = cloneDeep(this._problems);

            // Find the problem
            const problem = problems.find(item => item.id === id);

            return [
                200,
                problem
            ];
        });

        // -----------------------------------------------------------------------------------------------------
        // @ Filters - GET
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onGet('api/apps/providers/filters')
            .reply(() => [200, cloneDeep(this._filters)]);



        // -----------------------------------------------------------------------------------------------------
        // @ Folders - GET
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onGet('api/apps/providers/folders')
            .reply(() => {

                let count = 0;

                // Iterate through the folders
                this._folders.forEach((folder) => {

                    // Get the mails of this folder
                    const mails = this._mails.filter(mail => mail.folder === folder.id);

                    // If we are counting the 'sent' or the 'trash' folder...
                    if ( folder.slug === 'sent' || folder.slug === 'trash' )
                    {
                        // Always set the count to 0
                        count = 0;
                    }
                    // If we are counting the 'drafts' or the 'spam' folder...
                    else if ( folder.slug === 'drafts' || folder.slug === 'trash' || folder.slug === 'spam' )
                    {
                        // Set the count to the count of all mails
                        count = mails.length;
                    }
                    // Otherwise ('inbox')...
                    else
                    {
                        // Go through the mails and count the unread ones
                        mails.forEach((mail) => {

                            if ( mail.unread )
                            {
                                count++;
                            }
                        });
                    }

                    // Append the count to the folder mock-api
                    folder.count = count;

                    // Reset the count
                    count = 0;
                });

                // Return the response
                return [200, cloneDeep(this._folders)];
            });

        
        // -----------------------------------------------------------------------------------------------------
        // @ Filters - GET
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onGet('api/apps/providers/filters')
            .reply(() => [200, cloneDeep(this._filters)]);


        // -----------------------------------------------------------------------------------------------------
        // @ Labels - GET
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onGet('api/apps/patient/labels')
            .reply(() => [200, cloneDeep(this._labels)]);


        // -----------------------------------------------------------------------------------------------------
        // @ Contact - GET
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onGet('api/apps/providers/contact')
            .reply(({request}) => {

                // Get the id from the params
                const id = request.params.get('id');

                // Clone the providers
                const providers = cloneDeep(this._providers);

                // Find the contact
                const contact = providers.find(item => item.id === id);

                // Return the response
                return [200, contact];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Contact - POST
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onPost('api/apps/providers/contact')
            .reply(() => {

                // Generate a new contact
                const newContact = {
                    id          : CadMockApiUtils.guid(),
                    avatar      : null,
                    name        : 'New Contact',
                    emails      : [],
                    phoneNumbers: [],
                    job         : {
                        title  : '',
                        company: ''
                    },
                    birthday    : null,
                    address     : null,
                    notes       : null,
                    tags        : []
                };

                // Unshift the new contact
                this._providers.unshift(newContact);

                // Return the response
                return [200, newContact];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Contact - PATCH
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onPatch('api/apps/providers/contact')
            .reply(({request}) => {

                // Get the id and contact
                const id = request.body.id;
                const contact = cloneDeep(request.body.contact);

                // Prepare the updated contact
                let updatedContact = null;

                // Find the contact and update it
                this._providers.forEach((item, index, providers) => {

                    if ( item.id === id )
                    {
                        // Update the contact
                        providers[index] = assign({}, providers[index], contact);

                        // Store the updated contact
                        updatedContact = providers[index];
                    }
                });

                // Return the response
                return [200, updatedContact];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Contact - DELETE
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onDelete('api/apps/providers/contact')
            .reply(({request}) => {

                // Get the id
                const id = request.params.get('id');

                // Find the contact and delete it
                this._providers.forEach((item, index) => {

                    if ( item.id === id )
                    {
                        this._providers.splice(index, 1);
                    }
                });

                // Return the response
                return [200, true];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Countries - GET
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onGet('api/apps/providers/countries')
            .reply(() => [200, cloneDeep(this._countries)]);

        // -----------------------------------------------------------------------------------------------------
        // @ Tags - GET
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onGet('api/apps/providers/tags')
            .reply(() => [200, cloneDeep(this._tags)]);

        // -----------------------------------------------------------------------------------------------------
        // @ Tags - POST
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onPost('api/apps/providers/tag')
            .reply(({request}) => {

                // Get the tag
                const newTag = cloneDeep(request.body.tag);

                // Generate a new GUID
                newTag.id = CadMockApiUtils.guid();

                // Unshift the new tag
                this._tags.unshift(newTag);

                // Return the response
                return [200, newTag];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Tags - PATCH
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onPatch('api/apps/providers/tag')
            .reply(({request}) => {

                // Get the id and tag
                const id = request.body.id;
                const tag = cloneDeep(request.body.tag);

                // Prepare the updated tag
                let updatedTag = null;

                // Find the tag and update it
                this._tags.forEach((item, index, tags) => {

                    if ( item.id === id )
                    {
                        // Update the tag
                        tags[index] = assign({}, tags[index], tag);

                        // Store the updated tag
                        updatedTag = tags[index];
                    }
                });

                // Return the response
                return [200, updatedTag];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Tag - DELETE
        // -----------------------------------------------------------------------------------------------------
        this._cadMockApiService
            .onDelete('api/apps/providers/tag')
            .reply(({request}) => {

                // Get the id
                const id = request.params.get('id');

                // Find the tag and delete it
                this._tags.forEach((item, index) => {

                    if ( item.id === id )
                    {
                        this._tags.splice(index, 1);
                    }
                });

                // Get the providers that have the tag
                const providersWithTag = this._providers.filter(contact => contact.tags.indexOf(id) > -1);

                // Iterate through them and delete the tag
                providersWithTag.forEach((contact) => {
                    contact.tags.splice(contact.tags.indexOf(id), 1);
                });

                // Return the response
                return [200, true];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Avatar - POST
        // -----------------------------------------------------------------------------------------------------

        /**
         * Read the given file as mock-api url
         *
         * @param file
         */
        const readAsDataURL = (file: File): Promise<any> =>

            // Return a new promise
            new Promise((resolve, reject) => {

                // Create a new reader
                const reader = new FileReader();

                // Resolve the promise on success
                reader.onload = (): void => {
                    resolve(reader.result);
                };

                // Reject the promise on error
                reader.onerror = (e): void => {
                    reject(e);
                };

                // Read the file as the
                reader.readAsDataURL(file);
            })
        ;

        this._cadMockApiService
            .onPost('api/apps/providers/avatar')
            .reply(({request}) => {

                // Get the id and avatar
                const id = request.body.id;
                const avatar = request.body.avatar;

                // Prepare the updated contact
                let updatedContact: any = null;

                // In a real world application, this would return the path
                // of the saved image file (from host, S3 bucket, etc.) but,
                // for the sake of the demo, we encode the image to base64
                // and return it as the new path of the uploaded image since
                // the src attribute of the img tag works with both image urls
                // and encoded images.
                return from(readAsDataURL(avatar)).pipe(
                    map((path) => {

                        // Find the contact and update it
                        this._providers.forEach((item, index, providers) => {

                            if ( item.id === id )
                            {
                                // Update the avatar
                                providers[index].avatar = path;

                                // Store the updated contact
                                updatedContact = providers[index];
                            }
                        });

                        // Return the response
                        return [200, updatedContact];
                    })
                );
            });
    }
    
}
